<template>
  <section class="wedge-page">
    <div class="container">
      <div class="major-title wedge-page__page-title">
        {{ $t('message.wedge.title') }}
      </div>
      <WedgeHeadPane />
      <div class="content__panes-info">
        <img src="@/assets/img/icons/info.svg" alt="i" />
        <div class="text">
          {{ $t('message.wedge.info') }}
        </div>
      </div>
      <Wedge-layers v-if="isSlopeAlreadyAdded" ref="wedgeLayers" />
      <Wedge-fasteners />
      <Wedge-glu v-if="isSectorHasSlopes && isGluLayerInWedgeExist" ref="wedgeGlu" />
      <div class="step-buttons" v-if="isSectorHasSlopes">
        <button @click="backToSystemSettings" class="btn btn-back">
          <img src="@/assets/img/icons/step--back.svg" alt="←" />
          <span class="btn__text">
            {{ $t('message.stepButtons.setUpSystem') }}
          </span>
        </button>
        <div
          v-if="!isSomeWedgeLayerHasMaterial"
          content="Необходимо выбрать материал для Контруклона или Основного уклона"
          v-tippy="{
            maxWidth: 275
          }"
          class="btn btn-next btn--disabled"
        >
          <span class="btn__text">
            {{ $t('message.stepButtons.addJunction') }}
          </span>
          <img src="@/assets/img/icons/step--next.svg" alt="→" />
        </div>
        <button @click="goToJunctions" v-else class="btn btn-next">
          <span class="btn__text">
            {{ $t('message.stepButtons.addJunction') }}
          </span>
          <img src="@/assets/img/icons/step--next.svg" alt="→" />
        </button>
      </div>
    </div>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </section>
</template>

<script>
import WedgeHeadPane from '@/components/smart/WedgeHeadPane'
import WedgeLayers from '@/components/smart/Wedge/Wedge-layers'
import WedgeFasteners from '@/components/smart/Wedge/Wedge-fasteners'
import WedgeGlu from '@/components/smart/Wedge/Wedge-glu'
import { mapState, mapActions, mapMutations } from 'vuex'
import { stepNumbers } from '@/utils/stepNumbers'
import {
  isSectorHasSlopes,
  isSomeWedgeLayerHasMaterial,
  isTargetLayerInWedgeExist,
  isWedgeSquareExistAndValid,
  isWedgeGluValid
} from '@/utils/customFunctions'
import { scrollAndHighLightErrorField } from '@/utils'

export default {
  components: {
    WedgeHeadPane,
    WedgeLayers,
    WedgeFasteners,
    WedgeGlu
  },
  methods: {
    ...mapActions({
      fetchSlopeMaterials: 'fetchSlopeMaterials',
      updateStep: 'updateStep'
    }),
    ...mapMutations({
      ADD_SLOPE_TO_SECTOR: 'ADD_SLOPE_TO_SECTOR'
    }),
    backToSystemSettings() {
      this.$router.push(`/${this.$i18n.locale}/system/${this.sectorIndex}`)
    },
    goToJunctions() {
      if (this.isNextStepPermitted) {
        this.$router.push(`/${this.$i18n.locale}/junctions/${this.sectorIndex}`)
      } else {
        let target
        if (!this.isWedgeSquareExistAndValid) {
          target = this.$refs.wedgeLayers.$refs.square[0].$refs.inputWrapper
        } else {
          target = this.$refs.wedgeGlu.$refs.glu.$refs.inputWrapper
        }
        scrollAndHighLightErrorField(target)
      }
    }
  },
  mounted() {
    if (this.allSystems.length === 0) {
      this.$router.push(`/${this.$i18n.locale}/`)
    } else {
      if (this.slopeProducts.length === 0) {
        this.fetchSlopeMaterials({
          lang: this.$i18n.locale,
          sectorIndex: this.sectorIndex
        })
      } else {
        if (!this.isSlopeAlreadyAdded) {
          this.ADD_SLOPE_TO_SECTOR(this.sectorIndex)
        }
      }
    }
    this.updateStep(stepNumbers.wedge)
  },
  computed: {
    ...mapState({
      allSystems: state => state.systems,
      isLoading: state => state.pages.wedge.isLoading,
      slopeProducts: state => state.slopeProducts
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    isSectorHasSlopes() {
      return isSectorHasSlopes(this.sectorFromState)
    },
    isSomeWedgeLayerHasMaterial() {
      return isSomeWedgeLayerHasMaterial([
        this.sectorFromState.wedge.slopes.mainSlope,
        this.sectorFromState.wedge.slopes.contrSlope
      ])
    },
    isGluLayerInWedgeExist() {
      return isTargetLayerInWedgeExist(this.sectorFromState.layers, 'glue')
    },
    isSlopeAlreadyAdded() {
      return Object.prototype.hasOwnProperty.call(this.sectorFromState, 'wedge')
    },
    isNextStepPermitted() {
      return this.isWedgeSquareExistAndValid && this.isGluValid
    },
    isWedgeSquareExistAndValid() {
      return isWedgeSquareExistAndValid(this.sectorFromState.wedge)
    },
    isGluValid() {
      return isWedgeGluValid(this.sectorFromState.wedge, this.sectorFromState.layers, 'glue')
    }
  }
}
</script>

<style scoped></style>
