<template>
  <div class="import-link">
    <div class="import-link__title">
      {{ $t('message.wedge.importModal.title') }}
    </div>

    <i18n
      path="message.wedge.importModal.text"
      class="import-link__text"
      tag="div"
      v-if="!isImportStart"
    >
      <template #break>
        <br />
      </template>
      <template #link>
        <a :href="$t('message.wedge.importModal.url')" target="_blank">
          {{ $t('message.wedge.importModal.link') }}
        </a>
      </template>
    </i18n>

    <div class="import-link__input" v-if="!isImportStart">
      <div class="icon">
        <img src="@/assets/img/icons/import--link--grey.svg" alt="" />
      </div>
      <input type="text" class="import-link__input-input" v-model="link" />
      <SimpleButton color="green" @clickedFromSimpleBtn="importLink">
        {{ $t('message.wedge.importModal.btn') }}
      </SimpleButton>
    </div>
    <!--<div v-if="isImportStart">
      <button @click="errorLink">ошибка</button>
      <button @click="errorProject">ошибка</button>
      <button @click="success">успех</button>
    </div>-->

    <div class="import-link__message import-link__message--success" v-if="isSuccess">
      <div class="import-link__message-input">
        <div class="text">{{ this.link }}</div>
      </div>
      <SimpleButton color="red" @clickedFromSimpleBtn="close">
        {{ $t('message.wedge.importModal.continue') }}
      </SimpleButton>
    </div>

    <div class="import-link__message import-link__message--error" v-if="isErrorLink">
      <div class="import-link__message-input">
        <div class="text">{{ this.link }}</div>
      </div>
      <div class="import-link__text">
        {{ $t('message.wedge.importModal.errorLink') }}
      </div>
      <SimpleButton color="red" @clickedFromSimpleBtn="back">
        {{ $t('message.wedge.importModal.continue') }}
      </SimpleButton>
    </div>

    <div class="import-link__message import-link__message--error" v-if="isErrorProject">
      <div class="import-link__message-input">
        <div class="text">{{ this.link }}</div>
      </div>

      <i18n path="message.wedge.importModal.errorProject" tag="div" class="import-link__text">
        <template #break>
          <br />
        </template>
      </i18n>

      <SimpleButton color="red" @clickedFromSimpleBtn="back">
        {{ $t('message.wedge.importModal.continue') }}
      </SimpleButton>
    </div>
  </div>
</template>

<script>
import SimpleButton from '@/components/elements/Dom/Simple-button'
import { importWedgeFromLink } from '@/api'

export default {
  data: () => ({
    isImportStart: false,
    isSuccess: false,
    isErrorLink: false,
    isErrorProject: false,
    response: null,
    link: ''
  }),
  components: {
    SimpleButton
  },
  methods: {
    importLink() {
      this.$emit('toggleLoading')
      importWedgeFromLink(this.$i18n.locale, this.link)
        .then(response => {
          this.response = response.data
          this.$emit('toggleLoading')
          this.success()
        })
        .catch(error => {
          this.response = null
          this.$emit('toggleLoading')
          // console.log(error.response.status);
          if (error.response.status === 400) {
            this.errorLink()
          } else if (error.response.status === 409) {
            this.errorProject()
          }
        })
      // 400 ссылка спд
      // 409 не подходит
    },
    close() {
      this.$emit('importLink', this.response)
    },
    back() {
      this.response = null
      this.isErrorLink = false
      this.isSuccess = false
      this.isErrorProject = false
      this.isImportStart = false
    },
    errorLink() {
      this.isErrorLink = true
      this.isSuccess = false
      this.isErrorProject = false
      this.isImportStart = true
    },
    errorProject() {
      this.isErrorLink = false
      this.isSuccess = false
      this.isErrorProject = true
      this.isImportStart = true
    },
    success() {
      this.isErrorLink = false
      this.isSuccess = true
      this.isErrorProject = false
      this.isImportStart = true
    }
  }
}
</script>

<style scoped lang="sass">
.import-link
  max-width: rem(640)
  width: 95vw
  box-sizing: border-box
  +media((padding: (320: rem(40) rem(25), 600: rem(56) rem(60) rem(72))))
  &__title
    @extend .fs-24
    +media((font-size: (320: rem(18), 600: rem(24))))
    font-weight: bold
    +media((margin-bottom: (320: rem(20), 600: rem(32))))
  &__text
    +media((margin-bottom: (320: rem(20), 600: rem(32))))
    @extend .fs-14
    a
      color: $red
      transition: .5s
      font-weight: bold
      &:hover
        color: $red-hover
  &__input
    display: flex
    align-items: center
    justify-content: space-between
    flex-direction: row
    background: $white
    border: rem(1) solid #D6D6D6
    box-sizing: border-box
    border-radius: rem(4)
    height: rem(48)
    .icon
      height: 100%
      width: rem(46)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
    &-input
      width: calc(100% - 154px)
      border: none
      box-shadow: none
      outline: none
      height: 100%
      padding: 0 rem(20) 0 0
      text-overflow: ellipsis
      @extend .fs-12
      font-weight: bold
      font-family: $main-font
    .simple-btn
      width: rem(108)
      margin-right: rem(-1)
      height: rem(48)
      text-transform: uppercase
  &__message
    &-input
      @extend .fs-12
      font-weight: bold
      border: rem(1) solid #D6D6D6
      box-sizing: border-box
      border-radius: rem(4)
      padding: rem(14) rem(20) rem(12) rem(30)
      position: relative
      transition: .5s
      .text
        overflow: hidden
        text-overflow: ellipsis
      &:before
        position: absolute
        top: 50%
        transform: translate(-50%, -50%)
        left: 0
    &--success
      .import-link__message-input
        &:before
          content: url(~@/assets/img/icons/import--sucess.svg)
    &--error
      .import-link__message-input
        border: none
        box-shadow: 0 0 rem(6) $red
        background: $white
        color: $black
        &:before
          content: url(~@/assets/img/icons/import--error.svg)
    .simple-btn
      margin-top: rem(32)
      height: rem(48)
    .import-link__text
      padding: rem(16) 0 0
</style>
