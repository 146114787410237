<template>
  <section class="wedge-head">
    <div class="container">
      <div class="wedge-head__pane common-pane">
        <button class="import-btn" @click="showImport('ImportLink')">
          <span class="icon">
            <img src="@/assets/img/icons/import--link.svg" alt="import" />
          </span>
          <i18n path="message.wedge.pane.importLink" class="text">
            <template #break>
              <br />
            </template>
          </i18n>
        </button>
        <button class="import-btn import-btn--excel" @click="showImport('ImportTable')">
          <span class="icon">
            <img src="@/assets/img/icons/import--xls.svg" alt="import" />
          </span>
          <span class="text">
            {{ $t('message.wedge.pane.importXls') }}
          </span>
          <!-- <i18n path="message.wedge.pane.importXls" class="text">
            <template #break>
              <br />
            </template>
          </i18n> -->
        </button>
        <a href="https://nav.tn.ru/calculators/wedge-insulating/" target="_blank" class="use-btn">
          <i18n path="message.wedge.pane.useService" class="text">
            <template #break>
              <br />
            </template>
          </i18n>
          <span class="icon">
            <img src="@/assets/img/icons/import--ext.svg" alt="import" />
          </span>
        </a>
      </div>
    </div>
    <Modal :isModalOpen="isModalOpen" isShowClose @close="close">
      <template #body>
        <component
          :is="currentComponent"
          @close="close"
          @importTable="importTable"
          @importLink="importLink"
          @toggleLoading="toggleLoading"
        />
      </template>
    </Modal>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="5"
      :is-full-page="true"
    ></loading>
  </section>
</template>

<script>
import { mapMutations } from 'vuex'
import { calculateGlueSquare } from '@/utils/customFunctions'
import Modal from '@/components/elements/Modals/Modal'
import ImportLink from '@/components/smart/Wedge/ImportLink'
import ImportTable from '@/components/smart/Wedge/ImportTable'
export default {
  data: () => ({
    isModalOpen: false,
    currentComponent: 'ImportTable',
    isLoading: false
  }),
  components: {
    Modal,
    ImportLink,
    ImportTable
  },
  methods: {
    ...mapMutations({
      UPDATE_WEDGE_FASTENERS_ORDER: 'UPDATE_WEDGE_FASTENERS_ORDER',
      // IMPORT_WEDGE_PRODUCTS_FROM_LINK: 'IMPORT_WEDGE_PRODUCTS_FROM_LINK',
      UPDATE_SECTOR_WEDGE_GLUE: 'UPDATE_SECTOR_WEDGE_GLUE',
      IMPORT_WEDGE_PRODUCTS: 'IMPORT_WEDGE_PRODUCTS'
    }),
    close() {
      this.isModalOpen = false
    },
    showImport(component) {
      this.isModalOpen = true
      this.currentComponent = component
    },
    // importTable(fasteners) {
    //   this.isModalOpen = false
    //   // console.log(fasteners)
    //   this.UPDATE_WEDGE_FASTENERS_ORDER({
    //     sectorIndex: this.sectorIndex,
    //     value: fasteners
    //   })
    // },
    importTable(data) {
      this.isModalOpen = false
      // console.log(fasteners)
      this.IMPORT_WEDGE_PRODUCTS({
        sectorIndex: this.sectorIndex,
        products: data
      })
    },
    importLink(data) {
      this.close()
      this.IMPORT_WEDGE_PRODUCTS({
        sectorIndex: this.sectorIndex,
        products: data
      })
      const glueSquare = calculateGlueSquare(
        this.sectorSlopes.contrSlope.plates,
        this.sectorSlopes.mainSlope.plates
      )
      this.UPDATE_SECTOR_WEDGE_GLUE({
        sectorIndex: this.sectorIndex,
        value: Number(glueSquare.toFixed(2))
      })
    },
    toggleLoading() {
      this.isLoading = !this.isLoading
    }
  },
  computed: {
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    sectorSlopes() {
      return this.sectorFromState.wedge.slopes
    }
  }
}
</script>

<style scoped lang="sass">
.wedge-head
  &__pane
    display: grid
    overflow: hidden
    +media((grid-template-columns: (320: 1fr, 650: 1fr 1fr, 800: 12fr 12fr 22fr, 960: 11fr 10fr 9fr 18fr)))
    +media((border-radius: (320: 0, 960: rem(4))))
    .import, .use
      &-btn
        display: flex
        flex-direction: row
        align-items: center
        text-align: left
        @extend .clear-btn
        @extend .fs-12
        position: relative
        width: 100%
        &:hover
          color: $red
        .icon
          height: rem(64)
          display: flex
          flex-direction: row
          justify-content: center
          align-items: center
          position: relative
          width: rem(60)

        &--excel
          box-shadow: inset rem(-1) 0 0 #ECECEC, inset rem(1) 0 0 #ECECEC

    .import
      &-btn
        +media((box-shadow: (320: inset 0 rem(-1) 0 #ECECEC, 960: none)))
        +media((padding-right: (320: rem(16), 400: 0)))
        .text
          +media((width: (320: auto, 960: calc(100% - 60px))))
          br
            +media((display: (320: none, 960: inline-block)))
    .use
      &-btn
        +media((grid-column: (320: '1 / 2', 650: '1 / 3', 800: '3 / 5', 960: '4 / 5')))
        box-sizing: border-box
        text-decoration: none
        padding-left: rem(20)
        justify-content: space-between
        font-weight: bold
        background-repeat: no-repeat
        background-position: right center
        background-image: url("~@/assets/img/klin-bg.jpg")
        .icon
          background: rgba(255, 255, 255, 0.75)
</style>
