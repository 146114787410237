<template>
  <div class="container">
    <div
      class="wedge-layer common-pane"
      v-for="layer in this.$t('message.wedge').layers"
      :key="layer.id"
    >
      <div class="wedge-layer__header">
        <span class="wedge-layer__header-name">
          {{ layer.name }}
        </span>
        <div class="wedge-layer__header-right" v-if="isShowSquareInput(layer.id)">
          <div class="title">{{ layer.square }}</div>
          <wedge-square-input :square="wedgeSquare" @updateSquare="updateSquare" ref="square" />
        </div>
      </div>
      <div class="wedge-layer__body" v-if="isSectorHasSlopes && sectorSlopes[layer.id].id !== null">
        <div class="number">
          <div class="text" :class="{ 'text--extend': isSomeFastenerAttachWedge }">01</div>
          <div class="fastener" v-if="isSomeFastenerAttachWedge">
            <img src="@/assets/img/icons/wedge--fastener.svg" alt="" />
          </div>
        </div>
        <div class="content">
          <div class="content__material" @click="choseNewMaterial(layer.id)">
            <div class="content">
              <div class="type">{{ $t('message.wedge.insulation') }}</div>
              <div class="name">{{ findSlopeMaterialName(sectorSlopes[layer.id].id) }}</div>
            </div>
            <div class="icon">
              <div class="icon"><img src="@/assets/img/icons/filter--select.svg" alt="" /></div>
            </div>
          </div>
          <div class="content__plates">
            <div
              class="plate"
              v-for="(plate, i) in sectorSlopes[layer.id].plates"
              :key="`layer${layer.id}-plate-${i}`"
            >
              <div class="plate__controls">
                <div class="name">{{ plate.name }}</div>
                <Wedge-input :count="plate.count" :plateIndex="i" :slopeType="layer.id" />
              </div>
            </div>
          </div>
        </div>
        <button class="delete" @click="deleteMaterial(layer.id)">
          <img src="@/assets/img/icons/wedge--delete.svg" alt="" />
        </button>
      </div>
      <Wedge-add v-else :layerId="layer.id" :placement="'wedge'" @clickedAdd="choseNewMaterial" />
    </div>
    <Modal
      :isModalOpen="isModalOpen"
      :isNoRadius="true"
      :isCloseInHeader="true"
      :isShowClose="true"
      @close="close"
    >
      <template #body>
        <LayersProductsView
          :path="path"
          :placement="'wedge'"
          :slopeType="slopeType"
          :response="slopeContent"
          @close="close"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  calculateGlueSquare,
  isSectorHasSlopes,
  isSomeFastenerAttachWedge
} from '@/utils/customFunctions'
import Modal from '@/components/elements/Modals/Modal'
import LayersProductsView from '@/components/smart/layersProducts/ProductSelection'
import WedgeInput from '@/components/elements/Dom/Wedge-input'
import WedgeAdd from '@/components/elements/Dom/Wedge-add'
import WedgeSquareInput from '@/components/smart/Wedge/WedgeSquareInput'
const availableSlopes = ['contrSlope']

export default {
  data: () => ({
    isModalOpen: false,
    slopeType: 'mainSlope',
    path: [],
    layers: [
      {
        name: 'Основной уклон',
        id: 'mainSlope'
      },
      {
        name: 'Контруклон',
        id: 'contrSlope'
      }
    ]
  }),
  components: {
    WedgeSquareInput,
    Modal,
    LayersProductsView,
    WedgeInput,
    WedgeAdd
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_WEDGE_MATERIAL: 'UPDATE_SECTOR_WEDGE_MATERIAL',
      UPDATE_SECTOR_WEDGE_PARAM: 'UPDATE_SECTOR_WEDGE_PARAM'
    }),
    updateSquare(value) {
      if (value !== this.wedgeSquare) {
        this.UPDATE_SECTOR_WEDGE_PARAM({
          sectorIndex: this.sectorIndex,
          param: 'square',
          value: value
        })
      }
    },
    close() {
      this.isModalOpen = false
    },
    choseNewMaterial(slopeType) {
      this.slopeType = slopeType
      this.path = [
        slopeType === 'mainSlope'
          ? `${this.$t('message.wedge.mainSlope')}`
          : `${this.$t('message.wedge.contrSlope')}`,
        this.$t('message.wedge.insulation')
      ]
      this.isModalOpen = !this.isModalOpen
    },
    deleteMaterial(slopeType) {
      this.UPDATE_SECTOR_WEDGE_MATERIAL({
        sectorIndex: this.sectorIndex,
        slopeType: slopeType,
        id: null,
        plates: []
      })
    },
    findSlopeMaterialName(id) {
      return this.slopeProducts.find(p => p.id === id).name
    },
    calculateGlueSquare(contrSlopePlates, mainSlopePlates) {
      return calculateGlueSquare(contrSlopePlates, mainSlopePlates)
    },
    isShowSquareInput(slopeName) {
      return availableSlopes.includes(slopeName) && !!this.sectorSlopes[slopeName].id
    }
  },
  // mounted() {
  //   console.log(this.sectorFromState)
  // },
  computed: {
    ...mapState({
      slopeProducts: state => state.slopeProducts,
      slopeCategories: state => state.slopeCategories
    }),
    slopeContent() {
      return { categories: this.slopeCategories, products: this.slopeProducts }
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    isSomeFastenerAttachWedge() {
      return isSomeFastenerAttachWedge(this.sectorFromState.layers)
    },
    sectorSlopes() {
      return this.sectorFromState.wedge.slopes
    },
    isSectorHasSlopes() {
      return isSectorHasSlopes(this.sectorFromState)
    },
    wedgeSquare() {
      return this.sectorFromState.wedge.square
    }
  }
}
</script>

<style scoped lang="sass">
.wedge-layer
  +media((width: (320: 100%, 650: 92%, 960: 100%)))
  +media((margin: (320: 0 auto rem(8), 960: 0 0 rem(16))))
  &__header

    +media((padding: (320: rem(12) rem(16), 960: rem(16) rem(20))))
    box-shadow: inset 0 rem(-1) 0 #ECECEC
    display: flex
    +media((flex-direction: (320: column, 480: row)))
    +media((align-items: (320: flex-start, 480: center)))
    justify-content: space-between
    &-name
      @extend .fs-18
      font-weight: bold
      +media((font-size: (320: rem(16), 960: rem(18))))
      +media((padding-right: (480: rem(20))))
    &-right
      +media((padding-top: (320: rem(12), 480: 0)))
      display: flex
      align-items: center
      .title
        @extend .fs-12
        color: $light-black
        margin-right: rem(12)
  &__body
    display: grid
    +media((grid-template-rows: (320: 1fr 1fr, 576: 1fr)))
    +media((grid-template-columns: (320: rem(32) rem(8) 1fr rem(40), 576: 2fr 1fr 42fr 1fr 2fr)))
    .number
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      @extend .fs-12
      font-weight: bold
      color: #D6D6D6
      +media((grid-column: (320: '1 / 2', 576: '1 / 2')))
      .text
        &--extend
          height: 50%
          box-shadow: inset 0 rem(-1) 0 #ECECEC
          display: flex
          flex-direction: row
          align-items: center
          justify-content: center
      .fastener
        height: 50%
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
    .content
      +media((grid-column-start: (320: 4, 576: 3)))
      +media((grid-area: (320: '1 / 2 / 3 / 4', 576: '1 / 3 / 2 / 4')))
      &__material
        +media((padding: (320: rem(15) 0 rem(15) rem(16), 960: rem(15) 0)))
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        cursor: pointer
        box-sizing: border-box
        position: relative
        +media((height: (320: 50%, 576: auto)))
        +media((min-height: (320: rem(84), 576: unset)))
        // &:after
          content: ""
          width: 100vw
          height: rem(1)
          position: absolute
          bottom: 0
          left: calc(((100vw / 24) + 8px) * -1)
          background: #ECECEC
          +media((display: (320: block, 960: none)))
        .content
          .type
            @extend .fs-14
            font-weight: bold
            padding-bottom: rem(4)
          .name
            @extend .fs-12
            color: $light-black
        .icon
          +media((width: (320: rem(32), 576: rem(40))))
          +media((height: (320: rem(80), 576: rem(43))))
          display: flex
          flex-direction: row
          align-items: center
          justify-content: center
          box-shadow: inset rem(-1) 0 0 #ECECEC
      &__plates
        display: flex
        flex-direction: row
        align-items: center
        flex-wrap: wrap
        justify-content: flex-start
        box-sizing: border-box
        +media((padding: (320: rem(8) 0 rem(24), 960: rem(12) 0)))
        +media((box-shadow: (320: none, 576: inset 0 rem(1) 0 #ECECEC)))
        .plate
          &__controls
            display: flex
            +media((padding-top: (320: rem(7), 960: 0)))
            box-sizing: border-box
            +media((align-items: (320: flex-start, 576: center)))
            +media((flex-direction: (320: column, 576: row)))
            .name
              @extend .fs-12
              color: $light-black
              +media((padding: (320: 0 0 rem(6) 0, 576: 0 rem(6) 0 0)))
          &:not(:last-child)
            +media((margin-right: (320: rem(18), 450: rem(24), 960: rem(48))))
    .delete
      @extend .clear-btn
      +media((grid-column: (320: '4 / 6', 960: '5 / 6')))
</style>
