<template>
  <div class="square-input" ref="inputWrapper">
    <div class="icon" @click="focusInput">
      <img src="@/assets/img/icons/wedge--glu-edit.svg" alt="" />
    </div>
    <input
      type="number"
      :class="{ error: !isSquareValid }"
      v-model.number="computedSquare"
      :readonly="!isEditable"
      ref="input"
    />
    <div class="units">{{ $t('message.units.m2') }}</div>
  </div>
</template>

<script>
import { isValidNumber } from '@/utils'

export default {
  props: {
    square: {
      type: Number,
      default: 0,
      required: true
    }
  },
  name: 'WedgeSquareInput',
  data: () => ({
    isEditable: false
  }),
  methods: {
    focusInput() {
      this.isEditable = true
      this.$refs.input.focus()
      this.$refs.input.select()
    }
  },
  computed: {
    isSquareValid() {
      return isValidNumber(this.square)
    },
    computedSquare: {
      get() {
        return this.square
      },
      set(val) {
        if (isValidNumber(val)) {
          this.$emit('updateSquare', val)
        } else {
          this.$emit('updateSquare', this.square)
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.square-input
  border: rem(1) solid #D6D6D6
  box-sizing: border-box
  border-radius: rem(4)
  display: flex
  flex-direction: row
  align-items: center
  padding: rem(8) rem(12) rem(8) rem(8)
  +media((width: (320: rem(100), 400: rem(120), 960: rem(140))))
  &.v-error
    animation: blinking .5s linear infinite
  .icon
    transition: .5s
    opacity: .75
    cursor: pointer
    height: rem(24)
    width: rem(24)
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    &:hover
      opacity: 1
  input
    @extend .clear-input
    +media((padding: (320: 0 rem(4), 960: 0 rem(18) 0 rem(8))))
    +media((width: (320: rem(40), 960: rem(80))))
    box-sizing: border-box
    @extend .fs-14
    color: #999999
    font-weight: bold
    +media((font-size: (320: rem(12), 960: rem(14))))
    &.error
      color: $red
  .units
    @extend .fs-12
    font-weight: bold
    color: #999999
</style>
