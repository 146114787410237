<template>
  <button @click="clicked" class="wedge__add">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#94E3B5"></circle>
      <path
        d="M12 18V6M18 12H6"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="22.9256"
        stroke-linejoin="round"
      ></path>
    </svg>
    {{ $t('message.wedge.add') }}
  </button>
</template>

<script>
export default {
  props: {
    layerId: {
      type: String,
      default: 'contrSlope'
    },
    placement: String
  },
  methods: {
    clicked() {
      if (this.placement === 'wedge') {
        this.$emit('clickedAdd', this.layerId)
      } else {
        this.$emit('clickedAdd')
      }
    }
  }
}
</script>

<style scoped lang="sass">
.wedge
  &__add
    width: 100%
    padding: 0 rem(20)
    min-height: rem(56)
    display: flex
    flex-direction: row
    @extend .clear-btn
    @extend .fs-14
    font-weight: bold
    align-items: center
    background: #f2f2f2
    border: rem(1) dashed #D6D6D6
    box-sizing: border-box
    border-radius: rem(4)
    &:hover
      background: #fff
      box-shadow: $default-box-shadow
      border-color: transparent
      svg
        circle
          fill: $green
    svg
      margin-right: rem(20)
      circle
        transition: .5s
</style>
